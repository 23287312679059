import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import lossfat from "../assets/m/lossfat.png";
import definebody from "../assets/m/definebody.png";
import gainmuscle from "../assets/m/gainmuscle.png";
import lossfatf from "../assets/f/lossfat.png";
import definebodyf from "../assets/f/definebody.png";
import gainmusclef from "../assets/f/gainmuscle.png";
import { RUTAS } from "../constants/routes.constants";
import TranslatedLink from "../components/navigation/TranslatedLink";
import { useCookies } from "react-cookie";
import { useUser } from "../contexts/UserContext";

function Home({ intl }) {
  const [cookies, setCookie] = useCookies(["id"]);
  const { user, updateUser, setProgressBar } = useUser();
  setProgressBar(2);
  const lang = useLang();
  const iduserinfo = cookies.id;
  let count = 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatUrlString = (str) => {
    const withoutAccents = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return withoutAccents.toLowerCase().replace(/\s+/g, "-");
  };

  function saveUserinfo(field, value) {
    updateUser({ [field]: value });
  }

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "META.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "META.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container">
        <div className="row">
          <div className="col-12 my-3 recuadro text-center">
            <div className="title3 my-3">Dínos cuál es tu objetivo</div>
          </div>
        </div>
        <div className="row h-100 my-5">
          <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center flex-column">
            <TranslatedLink
              onClick={() => saveUserinfo("goal", "perder peso")}
              className="h-100 d-flex align-items-center justify-content-center flex-column"
              to={RUTAS.TARGET}
            >
              <img
                src={user.gender === "male" ? lossfat : lossfatf}
                className="img-fluid"
              />
              <div className="title1">perder peso</div>
            </TranslatedLink>
          </div>
          <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center flex-column">
            <TranslatedLink
              onClick={() => saveUserinfo("goal", "ganar músculo")}
              className="h-100 d-flex align-items-center justify-content-center flex-column"
              to={RUTAS.TARGET}
            >
              <img
                src={user.gender === "male" ? gainmuscle : gainmusclef}
                className="img-fluid"
              />
              <div className="title1">ganar músculo</div>
            </TranslatedLink>
          </div>
          <div className="col-12 col-lg-4 d-flex align-items-center justify-content-center flex-column">
            <TranslatedLink
              onClick={() => saveUserinfo("goal", "definir el cuerpo")}
              className="h-100 d-flex align-items-center justify-content-center flex-column"
              to={RUTAS.TARGET}
            >
              <img
                src={user.gender === "male" ? definebody : definebodyf}
                className="img-fluid"
              />
              <div className="title1">definir el cuerpo</div>
            </TranslatedLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Home);
