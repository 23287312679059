import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import vegan from "../assets/vegan.png";
import mediterranean from "../assets/mediterranean.png";
import vegetarian from "../assets/vegetarian.png";
import keto from "../assets/keto.png";
import body1829 from "../assets/age1829.png";
import { RUTAS } from "../constants/routes.constants";
import TranslatedLink from "../components/navigation/TranslatedLink";
import { FaArrowRight } from "react-icons/fa";
import { useUser } from "../contexts/UserContext";

function Home({ intl }) {
  const { user, updateUser, setProgressBar } = useUser();
  setProgressBar(6);
  const [dietDescription, setDietDescription] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const lang = useLang();
  let count = 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatUrlString = (str) => {
    const withoutAccents = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return withoutAccents.toLowerCase().replace(/\s+/g, "-");
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setDietDescription(value);
    event.preventDefault();
    if (showWarning) setShowWarning(false);
  };

  function saveUserinfo(field, value) {
    updateUser({ [field]: value });
  }

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "DIETA.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "DIETA.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 offset-lg-3">
            <div className="container">
              <div className="row">
                <div className="col-12 my-3 recuadro">
                  <div className="title3 my-3">
                    ¿Sigues algun tipo de dieta?
                  </div>
                </div>
                <div className="col-12 my-3 recuadro">
                  <TranslatedLink
                    onClick={() => saveUserinfo("diet", "Vegetariana")}
                    className=" d-flex justify-content-between align-items-center"
                    to={RUTAS.SUGAR}
                  >
                    <div className="my-3">
                      <div className="title1">Vegetariana</div>
                      <div className="desc1">Comida sin carne animal.</div>
                    </div>
                    <div>
                      <img src={vegetarian} className="icon-right" />
                    </div>
                  </TranslatedLink>
                </div>
                <div className="col-12 my-3 recuadro">
                  <TranslatedLink
                    onClick={() => saveUserinfo("diet", "Vegana")}
                    className=" d-flex justify-content-between align-items-center"
                    to={RUTAS.SUGAR}
                  >
                    <div className="my-3">
                      <div className="title1">Vegana</div>
                      <div className="desc1">
                        Comida que excluye todo producto de origen animal
                      </div>
                    </div>
                    <div>
                      <img src={vegan} className="icon-right" />
                    </div>
                  </TranslatedLink>
                </div>
                <div className="col-12 my-3 recuadro">
                  <TranslatedLink
                    onClick={() => saveUserinfo("diet", "Keto")}
                    className=" d-flex justify-content-between align-items-center"
                    to={RUTAS.SUGAR}
                  >
                    <div className="my-3">
                      <div className="title1">Keto</div>
                      <div className="desc1">
                        Dieta baja en carbohidratos y alta en grasas
                      </div>
                    </div>
                    <div>
                      <img src={keto} className="icon-right" />
                    </div>
                  </TranslatedLink>
                </div>
                <div className="col-12 my-3 recuadro">
                  <TranslatedLink
                    onClick={() => saveUserinfo("diet", "Mediterránea")}
                    className=" d-flex justify-content-between align-items-center"
                    to={RUTAS.SUGAR}
                  >
                    <div className="my-3">
                      <div className="title1">Mediterránea</div>
                      <div className="desc1">
                        Dieta equilibrada y basada en alimentos de origen
                        vegetal
                      </div>
                    </div>
                    <div>
                      <img src={mediterranean} className="icon-right" />
                    </div>
                  </TranslatedLink>
                </div>
                <div className="col-12 my-3 recuadro">
                  <div className=" d-flex justify-content-between align-items-center">
                    <div className="my-3">
                      <div className="title1">Otro tipo de dieta</div>
                      <div className="desc1">
                        Describe que tipo de dieta estas llevando a cabo:
                      </div>
                      <div className="d-flex justify-content-start">
                        <input
                          type="text"
                          className={`textbox ${showWarning ? "warning" : ""}`}
                          onChange={handleInputChange}
                        />
                        {showWarning && (
                          <div className="alert alert-warning position-absolute top-margin">
                            This field must not be empty
                          </div>
                        )}
                        {dietDescription.trim() ? (
                          <TranslatedLink
                            onClick={() =>
                              saveUserinfo("diet", dietDescription)
                            }
                            className="d-flex justify-content-between align-items-center btnnext"
                            to={RUTAS.SUGAR}
                          >
                            <FaArrowRight />
                          </TranslatedLink>
                        ) : (
                          <button
                            className="d-flex justify-content-between align-items-center btnnext"
                            onClick={() => setShowWarning(true)}
                          >
                            <FaArrowRight />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 my-3 recuadro">
                  <TranslatedLink
                    onClick={() =>
                      saveUserinfo("diet", "Ninguna dieta específica")
                    }
                    className=" d-flex justify-content-between align-items-center"
                    to={RUTAS.SUGAR}
                  >
                    <div className="my-3">
                      <div className="title1">Ninguna dieta específica</div>
                      <div className="desc1">
                        Sin tener en cuenta ninguna dieta específica de
                        alimentos
                      </div>
                    </div>
                  </TranslatedLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Home);
