import axios from "axios";
import { API } from "../../utils/index";
const instance = axios.create();

export const postUser = async (user) => {
  return instance.post(`${API}/user`, user);
};

export const verifyEmail = async (token) => {
  return instance.get(`${API}/user/verify-email?token=${token}`);
};
