import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import { getTexts } from "../api/text";

function PoliticaPrivacidad({ intl }) {
  const [text, setText] = useState("");
  const lang = useLang();

  useEffect(() => {
    window.scrollTo(0, 0);
    getTexts()
      .then((res) => {
        if (res.status === 200) {
          // console.log(res.data[0].);
          setText(res.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {/* <CustomHelmet
			title={intl.formatMessage({ id: 'POLITICAPRIVACIDAD.TITLESEO' }) || ''}
			description={intl.formatMessage({ id: 'POLITICAPRIVACIDAD.DESCRIPTIONSEO' }) || ''}
		/> */}
      <div className="container-fluid px-3 px-lg-5 pb-5 secondary-pages">
        <div className="row">
          <h3
            className="col-12"
            dangerouslySetInnerHTML={{ __html: text?.title?.[lang] }}
          ></h3>
          <div
            className="col-12"
            dangerouslySetInnerHTML={{ __html: text?.content?.[lang] }}
          ></div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(PoliticaPrivacidad);
