import React, { createContext, useState, useContext, useEffect } from "react";

const UserContext = createContext();

const initialUserState = {
  ageRank: "",
  birthdate: null,
  gender: "",
  height: null,
  weightHistory: [],
  initialWeight: null,
  targetWeight: null,
  goal: "",
  bodyType: "",
  bodyTypeObjective: "",
  diet: "",
  sugar: "",
  bodyFatLevel: "",
  areas: [],
  fitnessLevel: null,
  trainingExperience: null,
  trainingPlace: "",
  sports: [],
  previousProblems: [],
  extraGoals: [],
  timeDedicationBefore: "",
  timeDedicationNow: "",
  water: "",
  meals: [],
  otherGoals: [],
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : initialUserState;
  });
  const [progressBar, setProgressBar] = useState(1);

  const updateUser = (newData) => {
    // console.log("NEW_DATA: ", newData);
    setUser((prevUser) => {
      const updatedUser = { ...prevUser, ...newData };
      localStorage.setItem("user", JSON.stringify(updatedUser));
      return updatedUser;
    });
  };

  useEffect(() => {
    console.log("user", user);
  }, [user]);

  return (
    <UserContext.Provider
      value={{ user, updateUser, progressBar, setProgressBar }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
