import CookieConsent, { Cookies } from "react-cookie-consent";
import { useLang } from "../../translations/i18n";
import { FormattedMessage } from "react-intl";

function CookieConsentPopUp({ intl }) {
  const lang = useLang();
  const cookiePolicyUrl =
    lang === "es" ? "/politica-cookies" : "/en/cookie-policy";
  const buttonText = lang === "es" ? "Aceptar" : "I Understand";
  const declineButtonText = lang === "es" ? "Rechazar" : "Decline";
  const message =
    lang === "es"
      ? `Aquí puedes leer nuestra <a href="${cookiePolicyUrl}">Política de cookies</a>. Si sigues navegando en nuestra web, te muestras conforme con ésta.`
      : `Here you can read our <a href="${cookiePolicyUrl}">cookie policy</a>. If you continue browsing our website, you agree with it.`;

  return (
    <CookieConsent
      location="bottom"
      buttonText={buttonText}
      declineButtonText={declineButtonText}
      cookieName="userAcceptedCookies"
      style={{ background: "rgba(6,214,161,1)", color: "black" }}
      buttonStyle={{
        backgroundColor: "#000",
        color: "white",
        fontSize: "13px",
      }}
      declineButtonStyle={{
        background: "white",
        fontSize: "13px",
        color: "#535353",
      }}
      expires={150}
    >
      <small dangerouslySetInnerHTML={{ __html: message }}></small>
    </CookieConsent>
  );
}

export default CookieConsentPopUp;
