import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import brazos from "../assets/brazos.png";
import pecho from "../assets/pecho.png";
import barriga from "../assets/barriga.png";
import piernas from "../assets/piernas.png";
import { RUTAS } from "../constants/routes.constants";
import { FaArrowRight } from "react-icons/fa";
import TranslatedLink from "../components/navigation/TranslatedLink";
import { useUser } from "../contexts/UserContext";

function Home({ intl }) {
  const [selectedAreas, setSelectedAreas] = useState([]);
  const { user, updateUser, setProgressBar } = useUser();
  setProgressBar(5);
  const lang = useLang();
  let count = 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatUrlString = (str) => {
    const withoutAccents = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return withoutAccents.toLowerCase().replace(/\s+/g, "-");
  };

  const handleCheckboxChange = (event) => {
    console.log(event.target);
    const area = event.target.value;
    setSelectedAreas((prevSelectedAreas) =>
      prevSelectedAreas.includes(area)
        ? prevSelectedAreas.filter((a) => a !== area)
        : [...prevSelectedAreas, area]
    );
  };

  function saveUserinfo(field, value) {
    updateUser({ [field]: value });
  }

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "AREASPROBLEMATICAS.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "AREASPROBLEMATICAS.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 offset-lg-3">
            <div className="container">
              <div className="row">
                <div className="col-12 my-3 recuadro">
                  <div className="title3 my-3">
                    ¿Cuáles son tus áreas más problemáticas?
                  </div>
                </div>
                <div className="col-12 my-3 recuadro d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value="Pecho débil"
                      onChange={handleCheckboxChange}
                    />
                    <div>
                      <div className="title2">Pecho débil</div>
                      <div className="desc1">
                        Quiero reforzar mi torso superior
                      </div>
                    </div>
                  </div>
                  <img src={pecho} className="image-right" />
                </div>
                <div className="col-12 my-3 recuadro d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value="Brazos delgados"
                      onChange={handleCheckboxChange}
                    />
                    <div>
                      <div className="title2">Brazos delgados</div>
                      <div className="desc1">
                        Quiero tener unos brazos más musculados
                      </div>
                    </div>
                  </div>
                  <img src={brazos} className="image-right" />
                </div>
                <div className="col-12 my-3 recuadro d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value="Barriga cervezera"
                      onChange={handleCheckboxChange}
                    />
                    <div>
                      <div className="title2">Barriga cervecera</div>
                      <div className="desc1">
                        Quiero bajar mi barriga y perfilar mis abdominales
                      </div>
                    </div>
                  </div>
                  <img src={barriga} className="image-right" />
                </div>
                <div className="col-12 my-3 recuadro d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <input
                      type="checkbox"
                      className="checkbox"
                      value="Piernas delgadas"
                      onChange={handleCheckboxChange}
                    />
                    <div>
                      <div className="title2">Piernas delgadas</div>
                      <div className="desc1">
                        Quiero tener unas piernas más musculadas
                      </div>
                    </div>
                  </div>
                  <img src={piernas} className="image-right" />
                </div>
                <div className="col-12 d-flex justify-content-end px-0">
                  <TranslatedLink
                    onClick={() => saveUserinfo("areas", selectedAreas)}
                    className=" d-flex justify-content-between align-items-center btnnext"
                    to={RUTAS.DIET}
                  >
                    Siguiente <FaArrowRight />
                  </TranslatedLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Home);
