export const RUTAS = {
  BASE: {
    es: "/",
    en: "/en",
  },
  AGES: {
    es: "/edad",
    en: "/en/age",
  },
  META: {
    es: "/meta-de-tu-cuerpo",
    en: "/en/body-goal",
  },
  TARGET: {
    es: "/objetivo-de-tu-cuerpo",
    en: "/en/body-target",
  },
  BODYTYPE: {
    es: "/tipo-de-cuerpo",
    en: "/en/body-type",
  },
  PROBLEMATICAREAS: {
    es: "/areas-problematicas",
    en: "/en/problematic-areas",
  },
  DIET: {
    es: "/dieta",
    en: "/en/diet",
  },
  SUGAR: {
    es: "/nivel-de-azucar",
    en: "/en/sugar-level",
  },
  WATER: {
    es: "/consumo-de-agua",
    en: "/en/water-consume",
  },
  USERDATA: {
    es: "/datos-del-usuario",
    en: "/en/user-data",
  },
  REGISTER: {
    es: "/registro",
    en: "/en/register",
  },
  DONE: {
    es: "/done",
    en: "/en/done",
  },
  LEGAL: {
    es: "/aviso-legal",
    en: "/en/legal-notice",
  },
  PRIVACIDAD: {
    es: "/politica-privacidad",
    en: "/en/privacy-policy",
  },
  COOKIES: {
    es: "/politica-cookies",
    en: "/en/cookies-policy",
  },
  VERIFYEMAIL: {
    es: "/verify-email",
    en: "/en/verify-email",
  },
};
