import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import CustomHelmet from '../components/seo/CustomHelmet'
import { Link } from 'react-router-dom'

function Page404({ intl }) {


	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (<>
		<CustomHelmet
			title={intl.formatMessage({ id: 'P404.TITLESEO' }) || ''}
			description={intl.formatMessage({ id: 'P404.DESCRIPTIONSEO' }) || ''}
		/>
	<div className="container-fluid px-3 px-lg-5 pb-5">
			<div className="row">
				<div className="col-12">
					<h1>404</h1>
					<div className="mb-4">Contenido no encontrado · Contingut no trobat · Content not found</div>
					<Link to="/" className="text-white">Ir a la página de inicio</Link>
				</div>
			</div>
		</div>
	</>
    	)
}

export default injectIntl(Page404)
