import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import vegetarian from "../assets/vegetarian.png";
import { RUTAS } from "../constants/routes.constants";
import TranslatedLink from "../components/navigation/TranslatedLink";
import { FaArrowRight } from "react-icons/fa";
import Slider from "react-input-slider";
import { useUser } from "../contexts/UserContext";

function Home({ intl }) {
  const [state, setState] = useState({ x: 10 });
  const navigate = useNavigate();
  const { user, updateUser, setProgressBar } = useUser();
  setProgressBar(9);
  const lang = useLang();
  let count = 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const data = {};
    for (let entry of formData.entries()) {
      data[entry[0]] = entry[1];
    }
    data.fitnessLevel = state.x;
    updateUser(data);
    navigate(RUTAS.REGISTER[lang]);
  };

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "CONOCERTE.TITLESEO" }) || ""}
        description={
          intl.formatMessage({ id: "CONOCERTE.DESCRIPTIONSEO" }) || ""
        }
      />
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 offset-lg-3">
            <div className="container">
              <form className="row" onSubmit={handleSubmit}>
                <div className="col-12 my-3 recuadro d-flex justify-content-between align-items-top py-2">
                  <div className="my-3 w-75">
                    <div className="title3">Ayúdanos a conocerte</div>
                    <div className="title1">Estatura (en cm)</div>
                    <input
                      type="number"
                      className="textbox"
                      name="height"
                      required
                    />
                    <div className="title1">Peso actual (en kg)</div>
                    <input
                      type="number"
                      className="textbox"
                      name="initialWeight"
                      required
                    />
                    <div className="title1">Peso deseado (en kg)</div>
                    <input
                      type="number"
                      className="textbox"
                      name="targetWeight"
                      required
                    />
                  </div>
                  <div>
                    <img src={vegetarian} className="icon-right" />
                  </div>
                </div>
                <div className="col-12 my-3 recuadro d-flex justify-content-between align-items-top py-2">
                  <div className="my-3 w-75">
                    <div className="title1">Nivel de forma</div>
                    <div>
                      <div className="legendSlider d-flex justify-content-between">
                        <div>poco en forma</div>
                        <div>muy en forma</div>
                      </div>
                      <Slider
                        axis="x"
                        x={state.x}
                        xstep="10"
                        onChange={({ x }) =>
                          setState((state) => ({ ...state, x }))
                        }
                        styles={{
                          track: {
                            backgroundColor: "white",
                          },
                          active: {
                            background:
                              "linear-gradient(135deg, rgba(6,214,161,0.2861519607843137) 0%, rgba(6,214,161,1) 95%)",
                          },
                          thumb: {
                            width: 30,
                            height: 30,
                          },
                        }}
                      />
                    </div>
                    <div className="title1">
                      ¿Haces algun deporte actualmente? ¿Cuál?
                    </div>
                    <input
                      type="text"
                      className="textbox"
                      name="sports"
                      required
                    />
                    <div className="title1">
                      ¿Te representa alguno de estos problemas en cuanto a tus
                      entrenamientos?
                    </div>
                    <select name="previousProblems">
                      <option>Falta de motivación</option>
                      <option>Falta de tiempo</option>
                      <option>No tenía un plan claro</option>
                      <option>No tengo o mi entrenador es malo</option>
                      <option>Ninguna</option>
                    </select>
                  </div>
                  <div>
                    <img src={vegetarian} className="icon-right" />
                  </div>
                </div>
                <div className="col-12 my-3 recuadro d-flex justify-content-between align-items-top py-2">
                  <div className="my-3 w-75">
                    <div className="title1">
                      ¿Si haces fitness, donde entrenas?
                    </div>
                    <select name="trainingPlace">
                      <option>En casa</option>
                      <option>En un gimnasio</option>
                      <option>En el exterior</option>
                    </select>
                    <div className="title1">
                      ¿Y cuantas veces has entrenado a la semana en los últimos
                      meses?
                    </div>
                    <select name="timeDedicationBefore">
                      <option>Nada de nada</option>
                      <option>1 ó 2 veces a la semana</option>
                      <option>3 o 4 veces a la semana</option>
                      <option>Más de 4 veces a la semana</option>
                    </select>
                    <div className="title1">
                      ¿Cuánto tiempo estas dispuesto a dedicar a una sesión de
                      entrenamiento?
                    </div>
                    <select name="timeDedicationNow">
                      <option>30 minutos</option>
                      <option>1 hora</option>
                      <option>1 hora y media</option>
                      <option>Lo que sea necesario</option>
                    </select>
                  </div>
                  <div className="d-none d-lg-block">
                    <img src={vegetarian} className="icon-right" />
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-end px-0">
                  <button type="submit" className="btnnext">
                    Siguiente <FaArrowRight />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Home);
