import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import sugar from "../assets/sugar.png";
import nosugar from "../assets/no-sugar.png";
import lowsugar from "../assets/low-sugar.png";
import somesugar from "../assets/some-sugar.png";
import highsugar from "../assets/high-sugar.png";
import { RUTAS } from "../constants/routes.constants";
import TranslatedLink from "../components/navigation/TranslatedLink";
import { useUser } from "../contexts/UserContext";

function Home({ intl }) {
  const { user, updateUser, setProgressBar } = useUser();
  setProgressBar(7);
  const [dietDescription, setDietDescription] = useState("");
  const lang = useLang();
  let count = 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatUrlString = (str) => {
    const withoutAccents = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return withoutAccents.toLowerCase().replace(/\s+/g, "-");
  };

  function saveUserinfo(field, value) {
    updateUser({ [field]: value });
  }

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "AZUCAR.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "AZUCAR.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 offset-lg-3">
            <div className="container">
              <div className="row">
                <div className="col-12 my-3 recuadro">
                  <div className="title3 my-3">
                    ¿Cuánto azúcar consumes a la semana?
                  </div>
                </div>
                <div className="col-12 my-3 recuadro">
                  <TranslatedLink
                    onClick={() => saveUserinfo("sugar", "Apenas tomo azúcar")}
                    className=" d-flex justify-content-between align-items-center"
                    to={RUTAS.WATER}
                  >
                    <div className="my-3">
                      <div className="title1">Apenas tomo azúcar</div>
                      <div className="desc1">
                        Azúcar muy controlado, sin tomar bebidas azucaradas, ni
                        dulces, ni procesados.
                      </div>
                    </div>
                    <div>
                      <img src={nosugar} className="icon-right py-3" />
                    </div>
                  </TranslatedLink>
                </div>
                <div className="col-12 my-3 recuadro">
                  <TranslatedLink
                    onClick={() => saveUserinfo("sugar", "Ocasionalmente")}
                    className=" d-flex justify-content-between align-items-center"
                    to={RUTAS.WATER}
                  >
                    <div className="my-3">
                      <div className="title1">Ocasionalmente</div>
                      <div className="desc1">
                        Tomar alguna bebida azucarada o dulces de algunas veces
                        a la semana.
                      </div>
                    </div>
                    <div>
                      <img src={lowsugar} className="icon-right py-3" />
                    </div>
                  </TranslatedLink>
                </div>
                <div className="col-12 my-3 recuadro">
                  <TranslatedLink
                    onClick={() => saveUserinfo("sugar", "A menudo")}
                    className=" d-flex justify-content-between align-items-center"
                    to={RUTAS.WATER}
                  >
                    <div className="my-3">
                      <div className="title1">A menudo</div>
                      <div className="desc1">
                        Consumo de bebidas, dulces o procesados frecuentemente
                        durante la semana.
                      </div>
                    </div>
                    <div>
                      <img src={somesugar} className="icon-right py-3" />
                    </div>
                  </TranslatedLink>
                </div>
                <div className="col-12 my-3 recuadro">
                  <TranslatedLink
                    onClick={() => saveUserinfo("sugar", "Demasiado")}
                    className=" d-flex justify-content-between align-items-center"
                    to={RUTAS.WATER}
                  >
                    <div className="my-3">
                      <div className="title1">Demasiado</div>
                      <div className="desc1">
                        Un consumo habitual y continuado de dulces o de bebidas
                        azucaradas diariamente.
                      </div>
                    </div>
                    <div>
                      <img src={highsugar} className="icon-right py-3" />
                    </div>
                  </TranslatedLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Home);
