import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import appstore from "../assets/app-store-download-eatfity.webp";
import googleplay from "../assets/google-play-download-eatfity.webp";
import { verifyEmail } from "../api/user";

function EmailVerify() {
  const [verificationStatus, setVerificationStatus] =
    useState("Verificando...");
  const [statusType, setStatusType] = useState("info");
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    verifyEmail(token)
      .then((res) => {
        if (res.status === 200) {
          setVerificationStatus("¡Tu email ha sido verificado correctamente!");
          setStatusType("success");
        } else {
          setVerificationStatus(
            "Error al verificar email. Por favor, inténtelo de nuevo."
          );
          setStatusType("danger");
        }
      })
      .catch(() => {
        setVerificationStatus(
          "Ha ocurrido un error. Por favor, inténtelo más tarde.."
        );
        setStatusType("danger");
      });
  }, [location]);

  return (
    <div className="container mt-5">
      <div className={`alert alert-${statusType}`} role="alert">
        <h4 className="alert-heading">{verificationStatus}</h4>
        {statusType === "success" && (
          <div className="my-3">
            <p>
              ¡Bienvenido a nuestra comunidad! Ahora puedes utilizar todas las
              funcionalidades de nuestra aplicación.
            </p>
            <div className="my-3">
              <div className="title3 text-center">
                ¡Ya tienes tu cuenta disponible!
              </div>
              <div className="my-3 text-center">
                <p>
                  Ya puedes descargar tu aplicación e iniciar sesión con tu
                  nuevo usuario que acabas de crear.
                </p>
                <p>
                  Accede a eatfity y te ayudaremos a conseguir tus objetivos.
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-around my-5">
                <a
                  href="https://apps.apple.com/ua/app/eatfity/id6450825366"
                  target="_blank"
                >
                  <img src={appstore} className="img-fluid h90" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.eatfity&hl=es&gl=US"
                  target="_blank"
                >
                  <img src={googleplay} className="img-fluid h90" />
                </a>
              </div>
            </div>
          </div>
        )}
        {statusType === "danger" && (
          <p>
            Por favor, asegúrate de que el enlace es correcto o contacta con
            soporte para obtener ayuda.
          </p>
        )}
      </div>
    </div>
  );
}

export default EmailVerify;
