/* eslint-disable jsx-a11y/anchor-is-valid */
import "./App.css";
import { FormattedMessage, injectIntl } from "react-intl";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useMemo, useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "./assets/logo.png";
import { useTexts } from "./hooks/useTexts";
import {
  setLanguage,
  setLanguageHome,
  getConfig,
  useLang,
} from "./translations/i18n";

import { RUTAS } from "./constants/routes.constants";
import TranslatedLink from "./components/navigation/TranslatedLink";
import ScrollToTop from "./components/ScrollToTop";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { alertError, alertSuccess } from "./utils/logger";
import ProgressBar from "react-bootstrap/ProgressBar";
import { UserProvider } from "./contexts/UserContext";
import { useUser } from "./contexts/UserContext";
import CookieConsentPopUp from "./components/cookies/CookieConsentBanner";
import { FaAngleLeft } from "react-icons/fa";

/*function getEmptyReceiver() {
	return {
		email: '',
		role: 'receiver'
	}
}*/

const formateLanguage = (lang) => {
  if (lang) {
    if (lang === "es") return "Español";
    else if (lang === "en") return "English";
  } else return "ESPAÑOL";
};

const Top = ({
  handleClick,
  menuOpen,
  pathname,
  handleChangeHome,
  handleChange,
  currentLoc,
}) => {
  const lang = useLang();
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const activeMenuItemRef = useRef(null);
  const [languageMenu, setLanguageMenu] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(formateLanguage(lang));

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light apercubold`}
      style={{ backgroundColor: "#fff" }}
    >
      <div className="container">
        <span className="navbar-brand">
          <TranslatedLink to={RUTAS.BASE}>
            <img src={logo} alt="eatfity" className="logo" />
          </TranslatedLink>
        </span>
        <div className="titlesup">
          Sigue paso a paso nuestras preguntas y un profesional atenderá tu caso
          y te realizará un estudio personalizado
        </div>
      </div>
    </nav>
  );
};

function loadTagManager(cookieValue) {}

function ProgressBarValue(value) {
  const total = 10;
  const porc = (value.value * 100) / total;
  return (
    <ProgressBar
      className="mt-4 mb-1"
      animated
      now={porc}
      label={`paso ${value.value} de ${total}`}
    />
  );
}

function App({ intl }) {
  const [text, setText] = useState(null);
  const { pathname } = useLocation();
  const lang = useLang();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const { progressBar } = useUser();

  function handleClick(e, status) {
    e.stopPropagation();
    if (status) setMenuOpen(true);
    else setMenuOpen(false);
  }

  useEffect(() => {
    // eslint-disable-next-line
    const [_, url_lang] = pathname.split("/");
    if (url_lang === "en" && lang !== "en") setLanguage("en");
    else if (url_lang !== "en" && lang !== "es") setLanguage("es");

    console.log("pathname", pathname);
  }, [lang, pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (element) => (event) => {
    setLanguage(element, pathname);
  };

  const handleChangeHome = (element) => () => {
    setLanguageHome(element);
  };

  const handleNavigation = () => navigate(-1);

  return (
    <div className="App" onClick={(e) => handleClick(e, false)}>
      <CookieConsentPopUp />
      <header style={{ position: "relative" }}>
        <div className="container-fluid">
          <Top
            handleClick={handleClick}
            menuOpen={menuOpen}
            pathname={pathname}
            handleChangeHome={handleChangeHome}
            handleChange={handleChange}
            currentLoc={pathname}
          />
        </div>
        <div className="container">
          {!pathname.includes("politica-cookies") &&
            !pathname.includes("politica-privacidad") &&
            !pathname.includes("verify-email") && (
              <ProgressBarValue value={progressBar} />
            )}
          {pathname != "/" &&
            pathname != "/en" &&
            pathname != "/done" &&
            pathname != "/en/done" && (
              <button className="backbutton" onClick={handleNavigation}>
                <FaAngleLeft />
              </button>
            )}
        </div>
      </header>

      <Outlet />

      <ScrollToTop />

      <footer>
        <div className="container">
          <div className="row py-5">
            <div className="col-12 text-center">
              [{" "}
              <a
                href="https://eatfity.com/contacta-con-eatfity/"
                target="_blank"
              >
                Contactar con eatfity
              </a>{" "}
              ·{" "}
              <a href="https://eatfity.com" target="_blank">
                eatfity.com
              </a>{" "}
              |{" "}
              <TranslatedLink to={RUTAS.PRIVACIDAD} target="_blank">
                Política de privacidad
              </TranslatedLink>{" "}
              ·{" "}
              <TranslatedLink to={RUTAS.COOKIES} target="_blank">
                Política de cookies
              </TranslatedLink>{" "}
              ]
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default injectIntl(App);
