import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import profile from "../assets/profile.png";
import { RUTAS } from "../constants/routes.constants";
import { FaCheck } from "react-icons/fa";
import { useUser } from "../contexts/UserContext";
import { postUserinfo } from "../api/userinfo";
import { alertError } from "../utils/logger";

function Home({ intl }) {
  const [data, setData] = useState([]);
  const [state, setState] = useState({ x: 10, y: 10 });
  const { user, updateUser, setProgressBar } = useUser();
  setProgressBar(10);
  const [userAccount, setUserAccount] = useState();
  const navigate = useNavigate();
  const lang = useLang();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const userAccount = {
      fullName: formData.get("fullName"),
      email: formData.get("email"),
      password: formData.get("password"),
      role: "user",
    };
    setUserAccount(userAccount);
    const birthdate = formData.get("birthdate");
    updateUser({ ...user, ["birthdate"]: birthdate });
  };

  useEffect(() => {
    if (user && user.birthdate && userAccount) {
      console.log("ENTRO");
      postUserinfo({ userAccount, user })
        .then((res) => {
          console.log("res", res);
          if (res.status === 201) {
            navigate(RUTAS.DONE[lang]);
          }
        })
        .catch((err) => {
          alertError({
            error: err,
            customMessage:
              "No se ha podido guardar la información, inténtelo de nuevo",
          });
        });
    }
  }, [user]);

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "REGISTRO.TITLESEO" }) || ""}
        description={
          intl.formatMessage({ id: "REGISTRO.DESCRIPTIONSEO" }) || ""
        }
      />
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 offset-lg-3">
            <div className="container">
              <form className="row" onSubmit={handleSubmit}>
                <div className="col-12 my-3 recuadro d-flex justify-content-between align-items-top py-2">
                  <div className="my-3 w-75">
                    <div className="title3">Te ayudamos a crear tu cuenta</div>
                    <div className="title1">Tu nombre</div>
                    <input
                      type="text"
                      className="textbox"
                      name="fullName"
                      required
                    />
                    <div className="title1">Tu email</div>
                    <input
                      type="email"
                      className="textbox"
                      name="email"
                      required
                    />
                    <div className="title1">Tu fecha de nacimiento</div>
                    <input
                      type="date"
                      className="textbox"
                      name="birthdate"
                      required
                    />
                    <div className="title1">Contraseña</div>
                    <input
                      type="password"
                      className="textbox"
                      name="password"
                      minLength={8}
                      required
                    />
                  </div>
                  <div className="d-none d-lg-block">
                    <img src={profile} className="icon-right" />
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-end px-0">
                  <button type="submit" className="btnnext">
                    Registro <FaCheck />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Home);
