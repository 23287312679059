import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import nowater from "../assets/no-water.png";
import somewater from "../assets/some-water.png";
import highwater from "../assets/high-water.png";
import fullwater from "../assets/full-water.png";
import { RUTAS } from "../constants/routes.constants";
import TranslatedLink from "../components/navigation/TranslatedLink";
import { useUser } from "../contexts/UserContext";

function Home({ intl }) {
  const { user, updateUser, setProgressBar } = useUser();
  setProgressBar(8);
  const [dietDescription, setDietDescription] = useState("");
  const lang = useLang();
  let count = 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatUrlString = (str) => {
    const withoutAccents = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return withoutAccents.toLowerCase().replace(/\s+/g, "-");
  };

  function saveUserinfo(field, value) {
    updateUser({ [field]: value });
  }

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "AGUA.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "AGUA.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 offset-lg-3">
            <div className="container">
              <div className="row">
                <div className="col-12 my-3 recuadro">
                  <div className="title3 my-3">
                    ¿Qué cantidad de agua consumes al día?
                  </div>
                </div>
                <div className="col-12 my-3 recuadro">
                  <TranslatedLink
                    onClick={() => saveUserinfo("water", "Muy poco")}
                    className=" d-flex justify-content-between align-items-center"
                    to={RUTAS.USERDATA}
                  >
                    <div className="my-3">
                      <div className="title1">Muy poco</div>
                      <div className="desc1">
                        Apenas beber agua, solo zumos, cafè, té o alcohol.
                      </div>
                    </div>
                    <div>
                      <img src={nowater} className="icon-right py-3" />
                    </div>
                  </TranslatedLink>
                </div>
                <div className="col-12 my-3 recuadro">
                  <TranslatedLink
                    onClick={() =>
                      saveUserinfo("water", "Menos de medio litro")
                    }
                    className=" d-flex justify-content-between align-items-center"
                    to={RUTAS.USERDATA}
                  >
                    <div className="my-3">
                      <div className="title1">Menos de medio litro</div>
                      <div className="desc1">
                        Solo un par de vasos de agua al día.
                      </div>
                    </div>
                    <div>
                      <img src={somewater} className="icon-right py-3" />
                    </div>
                  </TranslatedLink>
                </div>
                <div className="col-12 my-3 recuadro">
                  <TranslatedLink
                    onClick={() =>
                      saveUserinfo(
                        "water",
                        "Entre medio litro y un litro y medio"
                      )
                    }
                    className=" d-flex justify-content-between align-items-center"
                    to={RUTAS.USERDATA}
                  >
                    <div className="my-3">
                      <div className="title1">
                        Entre medio litro y un litro y medio
                      </div>
                      <div className="desc1">
                        Entre 3 y 6 vasos de agua al día
                      </div>
                    </div>
                    <div>
                      <img src={highwater} className="icon-right py-3" />
                    </div>
                  </TranslatedLink>
                </div>
                <div className="col-12 my-3 recuadro">
                  <TranslatedLink
                    onClick={() =>
                      saveUserinfo("water", "Más de litro y medio")
                    }
                    className=" d-flex justify-content-between align-items-center"
                    to={RUTAS.USERDATA}
                  >
                    <div className="my-3">
                      <div className="title1">Más de litro y medio</div>
                      <div className="desc1">
                        Más de 7 vasos de agua al día.
                      </div>
                    </div>
                    <div>
                      <img src={fullwater} className="icon-right py-3" />
                    </div>
                  </TranslatedLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Home);
