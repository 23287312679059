export const PRO =
  process.env.NODE_ENV === "production" || process.env.NODE_ENV === "pro";

export const API = PRO
  ? "https://eatfity-api.owius.com:4003/api"
  : "http://localhost:4001/api";

export const SERVER_URL = PRO
  ? "https://eatfity-api.owius.com:4003"
  : "http://localhost:4001";
