import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import appstore from "../assets/app-store-download-eatfity.webp";
import googleplay from "../assets/google-play-download-eatfity.webp";

function Done({ intl }) {
  const [data, setData] = useState([]);
  const [state, setState] = useState({ x: 10, y: 10 });

  const lang = useLang();

  useEffect(() => {
    setState(5);
    window.scrollTo(0, 0);
  }, []);

  let count = 0;

  const formatUrlString = (str) => {
    const withoutAccents = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return withoutAccents.toLowerCase().replace(/\s+/g, "-");
  };

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "HOME.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "HOME.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 offset-lg-3">
            <div className="container">
              <div className="row">
                <div className="col-12 my-3 recuadro d-flex justify-content-between align-items-top py-2">
                  <div className="my-3">
                    <div className="title3 text-center">
                      ¡Ya tienes tu cuenta disponible!
                    </div>
                    <div className="my-3">
                      <p>
                        Ya puedes descargar tu aplicación e iniciar sesión con
                        tu nuevo usuario que acabas de crear. Revisa tu correo
                        que te acabamos de enviar para que valides tu usuario.
                      </p>
                      <p>
                        Accede a eatfity y te ayudaremos a conseguir tus
                        objetivos.
                      </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-around my-5">
                      <a
                        href="https://apps.apple.com/ua/app/eatfity/id6450825366"
                        target="_blank"
                      >
                        <img src={appstore} className="img-fluid h90" />
                      </a>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.eatfity&hl=es&gl=US"
                        target="_blank"
                      >
                        <img src={googleplay} className="img-fluid h90" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Done);
