import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import fem from "../assets/f/age1829.png";
import masc from "../assets/m/age3039.png";
import { RUTAS } from "../constants/routes.constants";
import TranslatedLink from "../components/navigation/TranslatedLink";
import { useCookies, Cookies } from "react-cookie";
import { useUser } from "../contexts/UserContext";

function Home({ intl }) {
  const [data, setData] = useState([]);
  const [userinfo, setUserinfo] = useState(getEmptyUserinfo());
  const [cookies, setCookie] = useCookies(["id"]);
  const { user, updateUser, setProgressBar } = useUser();
  setProgressBar(0);
  const lang = useLang();
  const iduserinfo = cookies.id;
  let count = 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function getEmptyUserinfo() {
    return {
      name: "",
    };
  }

  const formatUrlString = (str) => {
    const withoutAccents = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return withoutAccents.toLowerCase().replace(/\s+/g, "-");
  };

  function saveUserinfo(field, value) {
    updateUser({ [field]: value });
  }

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "HOME.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "HOME.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container">
        <div className="row">
          <div className="col-12 my-3 recuadro text-center">
            <div className="title3 my-3">¿Cuál es tu género?</div>
          </div>
        </div>
        <div className="row h-100 my-5">
          <div className="col-12 col-lg-6 h-100">
            <TranslatedLink
              onClick={() => saveUserinfo("gender", "female")}
              className="h-100 d-flex align-items-center justify-content-center flex-column"
              to={RUTAS.AGES}
            >
              <img src={fem} className="img-fluid" />
              <div className="title1">femenino</div>
            </TranslatedLink>
          </div>
          <div className="col-12 col-lg-6 h-100">
            <TranslatedLink
              onClick={() => saveUserinfo("gender", "male")}
              className="h-100 d-flex align-items-center justify-content-center flex-column"
              to={RUTAS.AGES}
            >
              <img src={masc} className="img-fluid" />
              <div className="title1">masculino</div>
            </TranslatedLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Home);
