import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../utils";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import fibrado from "../assets/m/fibrado.png";
import ejercitado from "../assets/m/ejercitado.png";
import delgadotonificado from "../assets/m/delgado-tonificado.png";
import crossfit from "../assets/m/crossfit.png";
import delgado from "../assets/m/delgado.png";
import musculado from "../assets/m/musculado.png";
import culturista from "../assets/m/culturista.png";
import fibradof from "../assets/f/fibrado.png";
import ejercitadof from "../assets/f/ejercitado.png";
import delgadotonificadof from "../assets/f/delgado-tonificado.png";
import crossfitf from "../assets/f/crossfit.png";
import delgadof from "../assets/f/delgado.png";
import musculadof from "../assets/f/musculado.png";
import culturistaf from "../assets/f/culturista.png";
import { RUTAS } from "../constants/routes.constants";
import TranslatedLink from "../components/navigation/TranslatedLink";
import { useUser } from "../contexts/UserContext";

function Home({ intl }) {
  const { user, updateUser, setProgressBar } = useUser();
  setProgressBar(3);
  const lang = useLang();
  let count = 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatUrlString = (str) => {
    const withoutAccents = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return withoutAccents.toLowerCase().replace(/\s+/g, "-");
  };

  function saveUserinfo(field, value) {
    updateUser({ [field]: value });
  }

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "OBJETIVO.TITLESEO" }) || ""}
        description={
          intl.formatMessage({ id: "OBJETIVO.DESCRIPTIONSEO" }) || ""
        }
      />
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 offset-lg-3">
            <div className="container">
              <div className="row">
                <div className="col-12 my-3 recuadro">
                  <div className="title3 my-3">
                    ¿Cuál es el tipo de cuerpo que te gustaría tener?
                  </div>
                </div>
                {user.goal == "ganar músculo" && (
                  <>
                    <div className="col-12 my-3 recuadro">
                      <TranslatedLink
                        onClick={() =>
                          saveUserinfo("bodyTypeObjective", "Fibrado")
                        }
                        className=" d-flex justify-content-between align-items-center"
                        to={RUTAS.BODYTYPE}
                      >
                        <div className="title1">Fibrado</div>
                        <div>
                          <img
                            src={user.gender === "male" ? fibrado : fibradof}
                            className="image-right"
                          />
                        </div>
                      </TranslatedLink>
                    </div>
                    <div className="col-12 my-3 recuadro">
                      <TranslatedLink
                        onClick={() =>
                          saveUserinfo("bodyTypeObjective", "Musculado")
                        }
                        className=" d-flex justify-content-between align-items-center"
                        to={RUTAS.BODYTYPE}
                      >
                        <div className="title1">Musculado</div>
                        <div>
                          <img
                            src={
                              user.gender === "male" ? musculado : musculadof
                            }
                            className="image-right"
                          />
                        </div>
                      </TranslatedLink>
                    </div>
                    <div className="col-12 my-3 recuadro">
                      <TranslatedLink
                        onClick={() =>
                          saveUserinfo("bodyTypeObjective", "Culturista")
                        }
                        className=" d-flex justify-content-between align-items-center"
                        to={RUTAS.BODYTYPE}
                      >
                        <div className="title1">Culturista</div>
                        <div>
                          <img
                            src={
                              user.gender === "male" ? culturista : culturistaf
                            }
                            className="image-right"
                          />
                        </div>
                      </TranslatedLink>
                    </div>
                  </>
                )}
                {user.goal == "perder peso" && (
                  <>
                    <div className="col-12 my-3 recuadro">
                      <TranslatedLink
                        onClick={() =>
                          saveUserinfo("bodyTypeObjective", "Delgado")
                        }
                        className=" d-flex justify-content-between align-items-center"
                        to={RUTAS.BODYTYPE}
                      >
                        <div className="title1">Delgado</div>
                        <div>
                          <img
                            src={user.gender === "male" ? delgado : delgadof}
                            className="image-right"
                          />
                        </div>
                      </TranslatedLink>
                    </div>
                    <div className="col-12 my-3 recuadro">
                      <TranslatedLink
                        onClick={() =>
                          saveUserinfo(
                            "bodyTypeObjective",
                            "Delgado y tonificado"
                          )
                        }
                        className=" d-flex justify-content-between align-items-center"
                        to={RUTAS.BODYTYPE}
                      >
                        <div className="title1">Delgado y tonificado</div>
                        <div>
                          <img
                            src={
                              user.gender === "male"
                                ? delgadotonificado
                                : delgadotonificadof
                            }
                            className="image-right"
                          />
                        </div>
                      </TranslatedLink>
                    </div>
                  </>
                )}
                {user.goal == "definir el cuerpo" && (
                  <>
                    <div className="col-12 my-3 recuadro">
                      <TranslatedLink
                        onClick={() =>
                          saveUserinfo("bodyTypeObjective", "Ejercitado")
                        }
                        className=" d-flex justify-content-between align-items-center"
                        to={RUTAS.BODYTYPE}
                      >
                        <div className="title1">Ejercitado</div>
                        <div>
                          <img
                            src={
                              user.gender === "male" ? ejercitado : ejercitadof
                            }
                            className="image-right"
                          />
                        </div>
                      </TranslatedLink>
                    </div>
                    <div className="col-12 my-3 recuadro">
                      <TranslatedLink
                        onClick={() =>
                          saveUserinfo("bodyTypeObjective", "De crossfit")
                        }
                        className=" d-flex justify-content-between align-items-center"
                        to={RUTAS.BODYTYPE}
                      >
                        <div className="title1">De crossfit</div>
                        <div>
                          <img
                            src={user.gender === "male" ? crossfit : crossfitf}
                            className="image-right"
                          />
                        </div>
                      </TranslatedLink>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Home);
