import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import body1829 from "../assets/m/age1829.png";
import body3039 from "../assets/m/age3039.png";
import body4049 from "../assets/m/age4049.png";
import bodymore50 from "../assets/m/agemore50.png";
import body1829f from "../assets/f/age1829.png";
import body3039f from "../assets/f/age3039.png";
import body4049f from "../assets/f/age4049.png";
import bodymore50f from "../assets/f/agemore50.png";
import { RUTAS } from "../constants/routes.constants";
import TranslatedLink from "../components/navigation/TranslatedLink";
import { useCookies, Cookies } from "react-cookie";
import { useUser } from "../contexts/UserContext";

function Home({ intl }) {
  const [data, setData] = useState([]);
  const [userinfo, setUserinfo] = useState(getEmptyUserinfo());
  const [cookies, setCookie] = useCookies(["id"]);
  const { user, updateUser, setProgressBar } = useUser();
  setProgressBar(1);
  const lang = useLang();
  const iduserinfo = cookies.id;
  let count = 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function getEmptyUserinfo() {
    return {
      name: "",
    };
  }

  const formatUrlString = (str) => {
    const withoutAccents = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return withoutAccents.toLowerCase().replace(/\s+/g, "-");
  };

  function saveUserinfo(field, value) {
    updateUser({ [field]: value });
  }

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "HOME.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "HOME.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container">
        <div className="row">
          <div className="col-12 my-3 recuadro text-center">
            <div className="title3 my-3">¿Cuál es tu rango de edad?</div>
          </div>
        </div>
        <div className="row h-100 my-5">
          <div className="col-12 col-lg-3 h-100">
            <TranslatedLink
              onClick={() => saveUserinfo("ageRank", "18-29")}
              className="h-100 d-flex align-items-center justify-content-center flex-column"
              to={RUTAS.META}
            >
              <img
                src={user.gender === "male" ? body1829 : body1829f}
                className="img-fluid"
              />
              <div className="title1">entre 18 y 29 años</div>
            </TranslatedLink>
          </div>
          <div className="col-12 col-lg-3 h-100">
            <TranslatedLink
              onClick={() => saveUserinfo("ageRank", "30-39")}
              className="h-100 d-flex align-items-center justify-content-center flex-column"
              to={RUTAS.META}
            >
              <img
                src={user.gender === "male" ? body3039 : body3039f}
                className="img-fluid"
              />
              <div className="title1">entre 30 y 39 años</div>
            </TranslatedLink>
          </div>
          <div className="col-12 col-lg-3 h-100">
            <TranslatedLink
              onClick={() => saveUserinfo("ageRank", "40-49")}
              className="h-100 d-flex align-items-center justify-content-center flex-column"
              to={RUTAS.META}
            >
              <img
                src={user.gender === "male" ? body4049 : body4049f}
                className="img-fluid"
              />
              <div className="title1">entre 40 y 49 años</div>
            </TranslatedLink>
          </div>
          <div className="col-12 col-lg-3 h-100">
            <TranslatedLink
              onClick={() => saveUserinfo("ageRank", "50+")}
              className="h-100 d-flex align-items-center justify-content-center flex-column"
              to={RUTAS.META}
            >
              <img
                src={user.gender === "male" ? bodymore50 : bodymore50f}
                className="img-fluid"
              />
              <div className="title1">más de 50 años</div>
            </TranslatedLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Home);
