import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import Initial from "./pages/Initial";
import Home from "./pages/Home";
import Meta from "./pages/Meta";
import Target from "./pages/Target";
import BodyType from "./pages/BodyType";
import AvisoLegal from "./pages/AvisoLegal";
import PoliticaPrivacidad from "./pages/PoliticaPrivacidad";
import PoliticaCookies from "./pages/PoliticaCookies";
import { RUTAS } from "./constants/routes.constants";
import NotFound from "./pages/Page404";
import ProblematicAreas from "./pages/ProblematicAreas";
import Diet from "./pages/Diet";
import Sugar from "./pages/Sugar";
import Water from "./pages/Water";
import UserData from "./pages/UserData";
import Register from "./pages/Register";
import Done from "./pages/Done";
import EmailVerify from "./pages/EmailVerify";

function Rutas() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path={RUTAS.BASE.es} element={<Initial />} />
          <Route path={RUTAS.BASE.en} element={<Initial />} />

          <Route path={RUTAS.AGES.es} element={<Home />} />
          <Route path={RUTAS.AGES.en} element={<Home />} />

          <Route path={RUTAS.META.es} element={<Meta />} />
          <Route path={RUTAS.META.en} element={<Meta />} />

          <Route path={RUTAS.TARGET.es} element={<Target />} />
          <Route path={RUTAS.TARGET.en} element={<Target />} />

          <Route path={RUTAS.BODYTYPE.es} element={<BodyType />} />
          <Route path={RUTAS.BODYTYPE.en} element={<BodyType />} />

          <Route
            path={RUTAS.PROBLEMATICAREAS.es}
            element={<ProblematicAreas />}
          />
          <Route
            path={RUTAS.PROBLEMATICAREAS.en}
            element={<ProblematicAreas />}
          />

          <Route path={RUTAS.DIET.es} element={<Diet />} />
          <Route path={RUTAS.DIET.en} element={<Diet />} />

          <Route path={RUTAS.SUGAR.es} element={<Sugar />} />
          <Route path={RUTAS.SUGAR.en} element={<Sugar />} />

          <Route path={RUTAS.WATER.es} element={<Water />} />
          <Route path={RUTAS.WATER.en} element={<Water />} />

          <Route path={RUTAS.USERDATA.es} element={<UserData />} />
          <Route path={RUTAS.USERDATA.en} element={<UserData />} />

          <Route path={RUTAS.REGISTER.es} element={<Register />} />
          <Route path={RUTAS.REGISTER.en} element={<Register />} />

          <Route path={RUTAS.DONE.es} element={<Done />} />
          <Route path={RUTAS.DONE.en} element={<Done />} />

          <Route path={RUTAS.LEGAL.es} element={<AvisoLegal />} />
          <Route path={RUTAS.LEGAL.en} element={<AvisoLegal />} />

          <Route path={RUTAS.PRIVACIDAD.es} element={<PoliticaPrivacidad />} />
          <Route path={RUTAS.PRIVACIDAD.en} element={<PoliticaPrivacidad />} />

          <Route path={RUTAS.COOKIES.es} element={<PoliticaCookies />} />
          <Route path={RUTAS.COOKIES.en} element={<PoliticaCookies />} />

          <Route path={RUTAS.VERIFYEMAIL.es} element={<EmailVerify />} />
          <Route path={RUTAS.VERIFYEMAIL.en} element={<EmailVerify />} />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Rutas;
