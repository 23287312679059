import React, { useState, useEffect } from "react";
import back from '../assets/scrollup.svg'
import {FaAngleUp} from 'react-icons/fa'; 

function ScrollToTop() {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
      window.addEventListener("scroll", () => {
          if (window.scrollY > 400) {
              setShowTopBtn(true);
          } else {
              setShowTopBtn(false);
          }
      });
  }, []);
  
  const goToTop = () => {
      window.scrollTo({
          top: 0,
          behavior: "smooth",
      });
  };

  //const doScroll = () => window.scrollTo(0, 0)

  return (
    <div className="top-to-btm">
      {" "}
      {showTopBtn && (<div onClick={goToTop}>
        <FaAngleUp className="icon-position" />
      </div>
      )}{" "}
    </div>
  )
}

export default ScrollToTop
