import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";
import { SERVER_URL } from "../utils";
import CustomHelmet from "../components/seo/CustomHelmet";
import { useLang } from "../translations/i18n";
import ectomorfo from "../assets/ectomorfo.png";
import mesomorfo from "../assets/mesomorfo.png";
import endomorfo from "../assets/endomorfo.png";
import { RUTAS } from "../constants/routes.constants";
import TranslatedLink from "../components/navigation/TranslatedLink";
import { useUser } from "../contexts/UserContext";

function Home({ intl }) {
  const { user, updateUser, setProgressBar } = useUser();
  setProgressBar(4);
  const lang = useLang();
  let count = 0;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatUrlString = (str) => {
    const withoutAccents = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return withoutAccents.toLowerCase().replace(/\s+/g, "-");
  };

  function saveUserinfo(field, value) {
    updateUser({ [field]: value });
  }

  return (
    <>
      <CustomHelmet
        title={intl.formatMessage({ id: "TIPOCUERPO.TITLESEO" }) || ""}
        description={intl.formatMessage({ id: "TIPOCUERPO.DESCRIPTIONSEO" }) || ""}
      />
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 offset-lg-3">
            <div className="container">
              <div className="row">
                <div className="col-12 my-3 recuadro">
                  <div className="title3 my-3">Tu tipo de cuerpo</div>
                </div>
                <div className="col-12 my-3 recuadro">
                  <TranslatedLink
                    onClick={() => saveUserinfo("bodyType", "Ectomorfo")}
                    className=" d-flex justify-content-between align-items-center"
                    to={RUTAS.PROBLEMATICAREAS}
                  >
                    <div>
                      <div className="title1">Ectomorfo</div>
                      <div className="desc1">
                        Poca grasa corporal y poco músculo. Cuesta ganar peso.
                      </div>
                    </div>
                    <div>
                      <img src={ectomorfo} className="image-right" />
                    </div>
                  </TranslatedLink>
                </div>
                <div className="col-12 my-3 recuadro">
                  <TranslatedLink
                    onClick={() => saveUserinfo("bodyType", "Mesomorfo")}
                    className=" d-flex justify-content-between align-items-center"
                    to={RUTAS.PROBLEMATICAREAS}
                  >
                    <div>
                      <div className="title1">Mesomorfo</div>
                      <div className="desc1">
                        Pérdida de grasa corporal normal y ganancia de músculo
                        rápida.
                      </div>
                    </div>
                    <div>
                      <img src={mesomorfo} className="image-right" />
                    </div>
                  </TranslatedLink>
                </div>
                <div className="col-12 my-3 recuadro">
                  <TranslatedLink
                    onClick={() => saveUserinfo("bodyType", "Endomorfo")}
                    className=" d-flex justify-content-between align-items-center"
                    to={RUTAS.PROBLEMATICAREAS}
                  >
                    <div>
                      <div className="title1">Endomorfo</div>
                      <div className="desc1">
                        Ganancia de peso fácil en comparación con otro tipo de
                        cuerpos.
                      </div>
                    </div>
                    <div>
                      <img src={endomorfo} className="image-right" />
                    </div>
                  </TranslatedLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(Home);
